import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Field } from 'formik'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Error = loadable(() => import('/src/components/Form/Error'))
const Required = loadable(() =>
    import('/src/components/Form/Required/Required')
)
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))

const IntegratedTextField = ({
    label,
    name,
    type,
    format,
    placeholder,
    fieldRef,
    submitIcon,
    isSubmitting,
    isSubmitted,
    className,
    required
}) => {
    const classes = [
        'c-integrated-textfield',
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <div className={concatenatedClasses}>
            {label && (
                <label htmlFor={name}>
                    {label}
                    {required && <Required />}
                </label>
            )}

            <div className={'c-integrated-textfield__inner'}>
                <Field
                    type={type ? type : 'text'}
                    inputMode={format === 'number' ? 'numeric' : 'text'}
                    pattern={format === 'number' ? '[0-9 ]+' : '.*'}
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    innerRef={fieldRef}
                    className={'form-control'}
                    disabled={isSubmitting || isSubmitted}
                />

                <SubmitButton
                    icon={submitIcon}
                    initialText={'Get Quote'}
                    sentText={'Thanks!'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </div>

            <Error name={name} />
        </div>
    )
}

IntegratedTextField.propTypes = {
    /**
     * Field label
     */
    label: PropTypes.string,
    /**
     * Field name
     */
    name: PropTypes.string.isRequired,
    /**
     * Field type
     */
    type: PropTypes.oneOf(['email', 'hidden']),
    /**
     * Format
     */
    format: PropTypes.oneOf(['number']),
    /**
     * Field placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Optional field ref
     */
    fieldRef: PropTypes.object,
    /**
     * Submit button icon
     */
    submitIcon: PropTypes.oneOf(['arrow', 'external']),
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Whether field is required
     */
    required: PropTypes.bool
}

IntegratedTextField.defaultProps = {
    name: 'firstname',
    submitIton: 'arrow',
    isSubmitting: false,
    isSubmitted: false,
    required: false
}

export default IntegratedTextField
